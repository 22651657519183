import Parse from "parse";













export class OD3_Core_Token extends Parse.Object {
  static __initStatic() {this.className = "OD3_Core_Token"}

  constructor(data) {
    super("OD3_Core_Token", data );
  }

  get key() {
    return super.get("key");
  }
  set key(value) {
    super.set("key", value);
  }
  get payload() {
    return super.get("payload");
  }
  set payload(value) {
    super.set("payload", value);
  }
  get url() {
    return super.get("url");
  }
  set url(value) {
    super.set("url", value);
  }
  get used() {
    return super.get("used");
  }
  set used(value) {
    super.set("used", value);
  }
} OD3_Core_Token.__initStatic();

Parse.Object.registerSubclass("OD3_Core_Token", OD3_Core_Token);

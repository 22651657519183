import Parse from "parse";

















export class OD3_Monitoring_ReportImage extends Parse.Object {
  static __initStatic() {this.className = "OD3_Monitoring_ReportImage"}

  constructor(data) {
    super("OD3_Monitoring_ReportImage", data );
  }

  get config() {
    return super.get("config");
  }
  set config(value) {
    super.set("config", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get source() {
    return super.get("source");
  }
  set source(value) {
    super.set("source", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get type() {
    return super.get("type");
  }
  set type(value) {
    super.set("type", value);
  }
} OD3_Monitoring_ReportImage.__initStatic();

Parse.Object.registerSubclass("OD3_Monitoring_ReportImage", OD3_Monitoring_ReportImage);

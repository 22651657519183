import Parse from "parse";













export class OD3_Permission extends Parse.Object {
  static __initStatic() {this.className = "OD3_Permission"}

  constructor(data) {
    super("OD3_Permission", data );
  }

  get key() {
    return super.get("key");
  }
  set key(value) {
    super.set("key", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} OD3_Permission.__initStatic();

Parse.Object.registerSubclass("OD3_Permission", OD3_Permission);

import Parse from "parse";




















export class OD3_Group extends Parse.Object {
  static __initStatic() {this.className = "OD3_Group"}

  constructor(data) {
    super("OD3_Group", data );
  }

  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
  get parentGroup() {
    return super.relation("parentGroup");
  }
  get roleIsVisible() {
    return super.get("roleIsVisible");
  }
  set roleIsVisible(value) {
    super.set("roleIsVisible", value);
  }
  get roleIsVisibleForTenant() {
    return super.get("roleIsVisibleForTenant");
  }
  set roleIsVisibleForTenant(value) {
    super.set("roleIsVisibleForTenant", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get users() {
    return super.relation("users");
  }
  get usersSeeEachOther() {
    return super.get("usersSeeEachOther");
  }
  set usersSeeEachOther(value) {
    super.set("usersSeeEachOther", value);
  }
} OD3_Group.__initStatic();

Parse.Object.registerSubclass("OD3_Group", OD3_Group);

import Parse from "parse";
















export class OD3_Push extends Parse.Object {
  static __initStatic() {this.className = "OD3_Push"}

  constructor(data) {
    super("OD3_Push", data );
  }

  get data() {
    return super.get("data");
  }
  set data(value) {
    super.set("data", value);
  }
  get installationId() {
    return super.get("installationId");
  }
  set installationId(value) {
    super.set("installationId", value);
  }
  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
  get type() {
    return super.get("type");
  }
  set type(value) {
    super.set("type", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
} OD3_Push.__initStatic();

Parse.Object.registerSubclass("OD3_Push", OD3_Push);

import Parse from "parse";
















export class AccessPermissions extends Parse.Object {
  static __initStatic() {this.className = "AccessPermissions"}

  constructor(data) {
    super("AccessPermissions", data );
  }

  get delete() {
    return super.get("delete");
  }
  set delete(value) {
    super.set("delete", value);
  }
  get owner() {
    return super.get("owner");
  }
  set owner(value) {
    super.set("owner", value);
  }
  get read() {
    return super.get("read");
  }
  set read(value) {
    super.set("read", value);
  }
  get source() {
    return super.get("source");
  }
  set source(value) {
    super.set("source", value);
  }
  get write() {
    return super.get("write");
  }
  set write(value) {
    super.set("write", value);
  }
} AccessPermissions.__initStatic();

Parse.Object.registerSubclass("AccessPermissions", AccessPermissions);

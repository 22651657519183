import Parse from "parse";














export class OD3_VirtualKPI extends Parse.Object {
  static __initStatic() {this.className = "OD3_VirtualKPI"}

  constructor(data) {
    super("OD3_VirtualKPI", data );
  }

  get pipe() {
    return super.get("pipe");
  }
  set pipe(value) {
    super.set("pipe", value);
  }
  get template() {
    return super.get("template");
  }
  set template(value) {
    super.set("template", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} OD3_VirtualKPI.__initStatic();

Parse.Object.registerSubclass("OD3_VirtualKPI", OD3_VirtualKPI);

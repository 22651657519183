import Parse from "parse";














export class OD3_Attachment extends Parse.Object {
  static __initStatic() {this.className = "OD3_Attachment"}

  constructor(data) {
    super("OD3_Attachment", data );
  }

  get file() {
    return super.get("file");
  }
  set file(value) {
    super.set("file", value);
  }
  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
} OD3_Attachment.__initStatic();

Parse.Object.registerSubclass("OD3_Attachment", OD3_Attachment);

import Parse from "parse";




















export class OD3_Dashboard extends Parse.Object {
  static __initStatic() {this.className = "OD3_Dashboard"}

  constructor(data) {
    super("OD3_Dashboard", data );
  }

  get heroWidget() {
    return super.get("heroWidget");
  }
  set heroWidget(value) {
    super.set("heroWidget", value);
  }
  get layout() {
    return super.get("layout");
  }
  set layout(value) {
    super.set("layout", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get source() {
    return super.get("source");
  }
  set source(value) {
    super.set("source", value);
  }
  get type() {
    return super.get("type");
  }
  set type(value) {
    super.set("type", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
  get widgets() {
    return super.get("widgets");
  }
  set widgets(value) {
    super.set("widgets", value);
  }
} OD3_Dashboard.__initStatic();

Parse.Object.registerSubclass("OD3_Dashboard", OD3_Dashboard);

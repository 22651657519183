import Parse from "parse";






















export class OD3_WidgetPreset extends Parse.Object {
  static __initStatic() {this.className = "OD3_WidgetPreset"}

  constructor(data) {
    super("OD3_WidgetPreset", data );
  }

  get config() {
    return super.get("config");
  }
  set config(value) {
    super.set("config", value);
  }
  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get imageLink() {
    return super.get("imageLink");
  }
  set imageLink(value) {
    super.set("imageLink", value);
  }
  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
  get layout() {
    return super.get("layout");
  }
  set layout(value) {
    super.set("layout", value);
  }
  get tags() {
    return super.get("tags");
  }
  set tags(value) {
    super.set("tags", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get type() {
    return super.get("type");
  }
  set type(value) {
    super.set("type", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
} OD3_WidgetPreset.__initStatic();

Parse.Object.registerSubclass("OD3_WidgetPreset", OD3_WidgetPreset);

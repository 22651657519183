import Parse from "parse";
















export class OD3_Monitoring_DataHierachies extends Parse.Object {
  static __initStatic() {this.className = "OD3_Monitoring_DataHierachies"}

  constructor(data) {
    super("OD3_Monitoring_DataHierachies", data );
  }

  get hierarchies() {
    return super.get("hierarchies");
  }
  set hierarchies(value) {
    super.set("hierarchies", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get source() {
    return super.get("source");
  }
  set source(value) {
    super.set("source", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} OD3_Monitoring_DataHierachies.__initStatic();

Parse.Object.registerSubclass("OD3_Monitoring_DataHierachies", OD3_Monitoring_DataHierachies);

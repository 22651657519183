import Parse from "parse";

















export class OD3_Notification extends Parse.Object {
  static __initStatic() {this.className = "OD3_Notification"}

  constructor(data) {
    super("OD3_Notification", data );
  }

  get data() {
    return super.get("data");
  }
  set data(value) {
    super.set("data", value);
  }
  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get iconPath() {
    return super.get("iconPath");
  }
  set iconPath(value) {
    super.set("iconPath", value);
  }
  get isSent() {
    return super.get("isSent");
  }
  set isSent(value) {
    super.set("isSent", value);
  }
  get title() {
    return super.get("title");
  }
  set title(value) {
    super.set("title", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
} OD3_Notification.__initStatic();

Parse.Object.registerSubclass("OD3_Notification", OD3_Notification);

import Parse from "parse";






















export class OD3_AlarmWebhook extends Parse.Object {
  static __initStatic() {this.className = "OD3_AlarmWebhook"}

  constructor(data) {
    super("OD3_AlarmWebhook", data );
  }

  get comment() {
    return super.get("comment");
  }
  set comment(value) {
    super.set("comment", value);
  }
  get extraOptions() {
    return super.get("extraOptions");
  }
  set extraOptions(value) {
    super.set("extraOptions", value);
  }
  get header() {
    return super.get("header");
  }
  set header(value) {
    super.set("header", value);
  }
  get method() {
    return super.get("method");
  }
  set method(value) {
    super.set("method", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get payload() {
    return super.get("payload");
  }
  set payload(value) {
    super.set("payload", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get url() {
    return super.get("url");
  }
  set url(value) {
    super.set("url", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
} OD3_AlarmWebhook.__initStatic();

Parse.Object.registerSubclass("OD3_AlarmWebhook", OD3_AlarmWebhook);

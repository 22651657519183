import Parse from "parse";















export class OD3_Widget extends Parse.Object {
  static __initStatic() {this.className = "OD3_Widget"}

  constructor(data) {
    super("OD3_Widget", data );
  }

  get config() {
    return super.get("config");
  }
  set config(value) {
    super.set("config", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get type() {
    return super.get("type");
  }
  set type(value) {
    super.set("type", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
} OD3_Widget.__initStatic();

Parse.Object.registerSubclass("OD3_Widget", OD3_Widget);

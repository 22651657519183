import Parse from "parse";













export class OD3_TenantTrustedDomain extends Parse.Object {
  static __initStatic() {this.className = "OD3_TenantTrustedDomain"}

  constructor(data) {
    super("OD3_TenantTrustedDomain", data );
  }

  get host() {
    return super.get("host");
  }
  set host(value) {
    super.set("host", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} OD3_TenantTrustedDomain.__initStatic();

Parse.Object.registerSubclass("OD3_TenantTrustedDomain", OD3_TenantTrustedDomain);

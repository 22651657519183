import Parse from "parse";



















export class OD3_Share extends Parse.Object {
  static __initStatic() {this.className = "OD3_Share"}

  constructor(data) {
    super("OD3_Share", data );
  }

  get accepted() {
    return super.get("accepted");
  }
  set accepted(value) {
    super.set("accepted", value);
  }
  get key() {
    return super.get("key");
  }
  set key(value) {
    super.set("key", value);
  }
  get targetRole() {
    return super.get("targetRole");
  }
  set targetRole(value) {
    super.set("targetRole", value);
  }
  get targetUser() {
    return super.get("targetUser");
  }
  set targetUser(value) {
    super.set("targetUser", value);
  }
  get type() {
    return super.get("type");
  }
  set type(value) {
    super.set("type", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
  get writePermission() {
    return super.get("writePermission");
  }
  set writePermission(value) {
    super.set("writePermission", value);
  }
} OD3_Share.__initStatic();

Parse.Object.registerSubclass("OD3_Share", OD3_Share);

import Parse from "parse";

















export class OD3_Monitoring_Jobs extends Parse.Object {
  static __initStatic() {this.className = "OD3_Monitoring_Jobs"}

  constructor(data) {
    super("OD3_Monitoring_Jobs", data );
  }

  get group() {
    return super.get("group");
  }
  set group(value) {
    super.set("group", value);
  }
  get interval() {
    return super.get("interval");
  }
  set interval(value) {
    super.set("interval", value);
  }
  get last_execution_ow_job() {
    return super.get("last_execution_ow_job");
  }
  set last_execution_ow_job(value) {
    super.set("last_execution_ow_job", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get next_execution_ow_job() {
    return super.get("next_execution_ow_job");
  }
  set next_execution_ow_job(value) {
    super.set("next_execution_ow_job", value);
  }
  get options() {
    return super.get("options");
  }
  set options(value) {
    super.set("options", value);
  }
  get type() {
    return super.get("type");
  }
  set type(value) {
    super.set("type", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
} OD3_Monitoring_Jobs.__initStatic();

Parse.Object.registerSubclass("OD3_Monitoring_Jobs", OD3_Monitoring_Jobs);
